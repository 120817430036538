<template>
  <div class="international_box">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="scrollList">
        <headSearch :search-out="headGo" />
        <div class="international_hard">
          <headSearch :search-out="headSearch" />
        </div>
        <div class="international_icon">
          <!--1-->
          <div v-if="dataListAll && dataListAll.banner" class="international_icon_img">
            <img :src="dataListAll.banner[0].url" alt="">
          </div>
          <!--2-->
          <div class="international_icon_son">
            <div
              v-for="(item,index) in dataListAll.trade"
              :key="index"
              class="international_icon_list"
              @click="tradeGo(item)"
            >
              <div class="international_icon_image">
                <img :src="item.img" alt="">
              </div>
              <h4>{{ item.typeName }}</h4>
            </div>
          </div>
        </div>
        <!--3-->
        <div class="international_three_box">
          <div
            v-for="(item,index) in (dataListAll && dataListAll.activity && dataListAll.activity[0].goodsInfo)"
            :key="index"
            class="international_three_list"
            @click="linkGo(item)"
          >
            <img :src="item.image" alt="">
          </div>
        </div>
        <!--4-->
        <div class="international_three_look">
          <div class="international_three_ov">
            <h4>推荐品牌</h4>
            <h5 @click="brandGoAll()">更多></h5>
          </div>
          <div
            v-for="(item,index) in dataListAll.brand"
            :key="index"
            class="international_three_look_list"
            @click="brandGo(item)"
          >
            <div class="international_three_look_img">
              <img :src="item.brandImg" alt="">
            </div>
            <h5>{{ item.brandName }}</h5>
          </div>
        </div>
        <!--5 area-->
        <div class="international_three_look_img">
          <imgCarousel :data-list-all="dataListAll.area" @yueTaoInternational="yueTaoInternational" />
        </div>
        <!--6-->
        <div class="international_tab">
          <div
            v-for="(item,index) in dataListAll.cate"
            :key="index"
            class="international_tab_list"
            @click="listId(item)"
          >
            <div :class=" isTab === index ? 'bf' : ''" @click="isTab=index">{{ item.category_name }}</div>
          </div>
          <div class="international_tab_list">
            <div @click="linkAll()">更多</div>
          </div>
        </div>
        <!--7-->
        <div class="jd_list_box">
          <div v-for="(item,index) in list" :key="index" class="jd_list" @click="listTo(item)">
            <div class="jd_list_ovs">
              <imgLoading class="jd_list_img" :src="item.productImage" />
            </div>
            <div class="jd_list_son">
              <div class="jd_name">
                <span>{{ item.productName }}</span>
              </div>
              <div class="jd_logo_ov">
                <span class="jd_price">会员价 ¥ {{ item.vipPrice }}</span>
              </div>
              <h6 class="jd_original_price">{{ item.channelName }}: ¥{{ item.price }}</h6>
            </div>
          </div>
          <div id="dataList" />
        </div>
      </div>
    </mescroll>
  </div>

</template>

<script>
import imgLoading from '@/component/imgLoading'
import { getXyHomeGoods, getXyHomeData } from '@/services/api'
import headSearch from '@/component/head/search'
import imgCarousel from '@/component/imgCarousel'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'YueTaoInternational',
  components: {
    headSearch,
    imgCarousel,
    mescroll,
    imgLoading
  },
  data() {
    return {
      isTab: 0, // tab
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#00fff3', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      },
      headSearch: {
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: false, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#00fff3', // 背景颜色
        placeholder: '搜索商品名称',
        searchBorderColor: '#00fff3', // 边框颜色
        boxHeight: '40px', // input 高度
        callbackClick: this.callbackClick // 回调
      },
      dataListAll: '', // list
      categoryId: 101997, // 金岗位 穿过来的但是现在写死
      list: '', // list

      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }

    }
  },
  mounted() {
    this.gitGetXyHomeGoods()
    this.gitXyHomeGoods()
  },

  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    // 搜索回调
    callbackClick: function() {
      this.$router.push({
        path: '/goodsSearch'
      })
    },
    // list
    gitGetXyHomeGoods: function() {
      getXyHomeData().then((res) => {
        if (Number(res.code) === 200) {
          this.dataListAll = res.data
        }
      })
    },
    // 点击 获取 id
    listId: function(item) {
      this.categoryId = item.category_id
      this.gitXyHomeGoods()
    },
    // 下边 list
    gitXyHomeGoods: function() {
      const data = {
        categoryFirstId: this.categoryId,
        page: 1,
        pageSize: 10
      }
      getXyHomeGoods(data).then((res) => {
        if (Number(res.code) === 200) {
          this.list = res.data.data
        }
      })
    },
    // 头部四个 img 跳转
    tradeGo: function(item) {
      this.$router.push({
        path: '/sidebar',
        query: {
          channelId: item.channelId,
          indexKey: item.indexKey,
          goodTypeId: item.id,
          keyword: item.typeName,
          brand: 44
        }
      })
    },
    // 推荐品牌 跳转 更多
    brandGoAll: function() {
      this.$router.push({
        path: '/brandList'
      })
    },
    // 推荐品牌 跳转
    brandGo: function(item) {
      // brand 33
      this.$router.push({
        path: '/sidebar',
        query: {
          goodTypeId: item.brandId,
          keyword: item.brandName,
          brand: 33
        }
      })
    },
    // 轮播 img
    yueTaoInternational: function(item) {
      this.$router.push({
        path: '/sidebar',
        query: {
          keyword: item.name,
          channelId: item.channelId,
          indexKey: item.indexKey,
          goodTypeId: item.id,
          brand: 55
        }
      })
    },
    // 点击 tab 更多 跳转
    linkAll: function() {
      this.$router.push({
        path: '/sidebar',
        query: {
          keyword: 'categoryFirstId',
          channelId: 7,
          indexKey: 'categoryFirstId',
          goodTypeId: 101997,
          brand: 66
        }
      })
    },

    // 中间 4个img 跳转 详情
    linkGo: function(item) {
      const obj = {
        'product_type': 1, // 暂时写1  自营
        'goodId': item.id,
        'skuId': item.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    // 下边 list 跳转 详情
    listTo: function(item) {
      const obj = {
        'product_type': item.product_type,
        'goodId': item.productId,
        'skuId': item.skuId
      }
      this.$store.dispatch('godetail', obj)
    },

    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        categoryFirstId: this.categoryId,
        page: pageNum,
        pageSize: pageSize
      }
      getXyHomeGoods(data).then(res => {
        successCallback(res.data.data)
      })
    }

  }

}
</script>

<style scoped lang="less">
/deep/ .search_middle span {
  color: #fff;
}

.international_box {
  .scrollList {
    background: #f6f6f6;
  }

  background: #f6f6f6;

  .international_hard {
    width: 100%;
    height: 140px;
    background: #00fff3;
    padding-top: 14px;
    box-sizing: border-box;
  }

  .international_icon {
    width: 100%;
    height: 200px;
    background: #ffffff;
    position: relative;
    margin: 0 auto;

    .international_icon_img {
      margin: 12px;
      position: absolute;
      top: -90px;
      border-radius: 6px;
      overflow: hidden;
      height: 150px;

    }

    .international_icon_son {
      padding-top: 100px;
      overflow: hidden;

      .international_icon_list {
        width: 25%;
        float: left;

        .international_icon_image {
          width: 55px;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }

        h4 {
          margin-top: 12px;
        }
      }
    }
  }

  .international_three_box {
    margin: 12px;
    border-radius: 4px;
    overflow: hidden;

    .international_three_list {
      width: 175px;
      min-height: 106px;
      background: #fff;
      float: left;
      margin-right: 1px;
      margin-top: 1px;
      padding: 12px 8px;
      text-align: left;
      position: relative;

      img {
        width: 100%;
      }
    }

    .international_three_list:nth-child(2n) {
      margin-right: 0px;
    }
  }

  .international_three_look {
    margin: 12px;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;

    .international_three_ov {
      overflow: hidden;
      padding: 0 0 12px 0;
      box-sizing: border-box;

      h4 {
        float: left;
      }

      h5 {
        float: right;
      }
    }

    .international_three_look_list {
      width: 25%;
      float: left;

      .international_three_look_img {

      }

      h5 {
        font-size: 12px;
        color: #999;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        margin-top: 12px;
      }
    }
  }

  .international_tab {
    margin: 12px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    background: #fff;
    padding: 12px 0;
    box-sizing: border-box;

    .international_tab_list {
      flex: 1;

      .bf {
        position: relative;
        color: #000;
        font-weight: 700;
      }

      .bf:before {
        content: "";
        position: absolute;
        width: 56px;
        height: 2px;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 0%);
        background: #fb947e;
      }
    }
  }

  .jd_list_box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    box-sizing: border-box;
    overflow: hidden;

    .jd_list {
      width: 169px;
      height: 280px;
      background: #fff;
      margin-right: 12px;
      float: left;
      margin-bottom: 6px;
      margin-top: 6px;
      border-radius: 6px;
      overflow: hidden;
    }

    .jd_list:nth-child(2n) {
      margin-right: 0px;
    }

    .jd_list_ovs {
      position: relative;

      .jd_list_img {
        width: 100%;
      }
    }

    .jd_list_son {
      padding: 0 7px;
      box-sizing: border-box;
      text-align: left;
      margin-top: 6px;

      h6 {
        text-decoration: line-through;
        color: #333;
        font-size: 12px;
        margin-top: 6px;
      }

      .jd_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;

        .jd_logo {
          background: #ff001c;
          color: #fff;
          border-radius: 3px;
          padding: 0px 1px;
          font-weight: bold;
          box-sizing: border-box;
          font-size: 8px;
          margin-right: 3px;
        }
      }

      .jd_logo_ov {
        margin-top: 10px;

        .jd_price {
          color: #ff001c;
          font-size: 16px;
        }
      }

    }

    .no_list {
      padding: 12px;
      box-sizing: border-box;
    }
  }
}
</style>
