<template>
  <div>
    <div class="my-swiper">
      <swiper :options="swiperOption" class="swiper-container">
        <swiper-slide v-for="(item, index) of dataListAll" :key="index" class="swiper-item">
          <img class="swiper-img" :src="item.img" @click="areaGO(item)">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

import 'swiper/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Lunbo',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    dataListAll: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      swiperOption: {
        direction: 'horizontal',
        loop: true,
        // autoplay: {   // 开启动画自动轮播
        //   delay: 1000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 20, // 间隙
        loopAdditionalSlides: 0
      }
    }
  },
  mounted() {

  },
  methods: {
    areaGO: function(item) {
      this.$emit('yueTaoInternational', item)
    }
  }
}
</script>

<style scoped lang="less">

.my-swiper {
  overflow: hidden;
  width: 375px;
}

.swiper-container .swiper-wrapper .swiper-slide {
  width: 230px;
  border-radius: 4px;
}

.swiper-container .swiper-wrapper .swiper-slide img {
  width: 230px;
  height: 130px;
  border-radius: 4px;
}

.swiper-container .swiper-wrapper .swiper-slide-prev {
  margin-top: 15px;
  height: 100px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-prev img {
  height: 100px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-next {
  margin-top: 15px;
  height: 100px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-next img {
  height: 100px !important;
}

</style>
